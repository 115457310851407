<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    :class="{ 'text-gray': !display_value }"
                    class="form-label disabled-field"
                >
                    {{ display_value || translate("(Not Set)") }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <default-tag-input
            ref="field"
            :id="id"
            :name="name"
            :value="value"
            :id-field="idField"
            :label-field="labelField"
            :empty-label="emptyLabel"
            :empty-value="emptyValue"
            :data-source="dataSource"
            :stored-data-source="storedDataSource"
            :show-all="showAll"
            :ad-hoc="adHoc"
            :glyph="glyph"
            :max-picks="maxPicks"
            :required="required"
            :disabled="disabled"
            @input="pick"
            @create="create"
            @options-loaded="cache_options"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import DefaultTagInput from "./DefaultTagInput"

export default {
    name: "FormTagInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DefaultTagInput
    },
    methods: {
        pick (value, field, option) {
            if(this.editable) this.$emit("input", value, field, option)
        },
        create (label, field) {
            if(this.editable) this.$emit("create", label, field)
        },
        cache_options (options) {
            this.options = [...options]
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        display_value () {
            if(!!this.value && !!this.value.length) {
                const options = this.options.slice()
                return this.value.map(id => {
                    const option = options.find(option => option[this.idField] === id) || { [this.labelField]: id }
                    return option[this.labelField]
                }).join(", ")
            }
            return null
        }
    },
    props: {
        ...DefaultTagInput.props,
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        dataSource: { // either an array of options (locally sourced) OR an entity name (remote source ; api endpoint /<entity>/autosuggest/<name>)
            validator: prop => !!prop && (Array.isArray(prop) || (typeof prop === "string")),
            required: true,
            default () {
                return []
            }
        },
        storedDataSource: {
            type: Boolean,
            default: false
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        showAll: {
            type: Boolean,
            default: true
        },
        minOptionQueryLength: {
            type: Number,
            default: 3
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            options: []
        }
    }
}
</script>
