<template>
    <div
        :id="id"
        :class="{ [`nibnut-editor-${size}`]: true }"
        class="nibnut-editor"
    >
        <div v-if="!!editor">
            <editor-menu-bar
                :editor="editor"
            >
                <div
                    slot-scope="{ commands, isActive, getMarkAttrs }"
                    class="nibnut-editor-toolbar"
                >
                    <button
                        v-for="level in headers"
                        :key = level
                        type="button"
                        :class="{ 'btn-primary': isActive.heading({ level }), 'btn-link': !isActive.heading({ level }) }"
                        :disabled="!editable"
                        @click="commands.heading({ level })"
                        class="btn btn-sm"
                    >
                        {{ `H${level}` }}
                    </button>

                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.bold(), 'btn-link': !isActive.bold() }"
                        :disabled="!editable"
                        @click="commands.bold"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="bold" :title="$root.translate('Bold')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.italic(), 'btn-link': !isActive.italic() }"
                        :disabled="!editable"
                        @click="commands.italic"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="italic" :title="$root.translate('Italic')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.underline(), 'btn-link': !isActive.underline() }"
                        :disabled="!editable"
                        @click="commands.underline"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="underline" :title="$root.translate('Underline')" />
                    </button>
                    <button
                        v-if="styles"
                        type="button"
                        :class="{ 'btn-primary': isActive.strike(), 'btn-link': !isActive.strike() }"
                        :disabled="!editable"
                        @click="commands.strike"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="strikethrough" :title="$root.translate('Strikethrough')" />
                    </button>

                    <button
                        type="button"
                        :class="{ 'btn-primary': isActive.small(), 'btn-link': !isActive.small() }"
                        :disabled="!editable"
                        @click="commands.small"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="text-height" :title="$root.translate('Small')" />
                    </button>

                    <button
                        v-if="links"
                        type="button"
                        :class="{ 'btn-primary': isActive.link(), 'btn-link': !isActive.link() }"
                        :disabled="!editable"
                        @click="link_edit(commands.link, getMarkAttrs('link'))"
                        class="btn btn-sm mr-3"
                    >
                        <open-icon glyph="link" :title="$root.translate('Link')" />
                    </button>

                    <button
                        v-if="lists"
                        type="button"
                        :class="{ 'btn-primary': isActive.bullet_list(), 'btn-link': !isActive.bullet_list() }"
                        :disabled="!editable"
                        @click="commands.bullet_list"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="list" :title="$root.translate('Bullet List')" />
                    </button>

                    <button
                        v-if="quotes"
                        type="button"
                        :class="{ 'btn-primary': isActive.blockquote(), 'btn-link': !isActive.blockquote() }"
                        :disabled="!editable"
                        @click="commands.blockquote"
                        class="btn btn-sm"
                    >
                        <open-icon glyph="quote-right" :title="$root.translate('Block Quote')" />
                    </button>

                    <base-dropdown
                        v-if="!!tokens && !!tokens.length"
                        :id="`${id}-token-picker`"
                        name="token_id"
                        :value="token_id"
                        size="sm"
                        label-field="label"
                        emptyValue=""
                        :emptyLabel="translate('Insert Dynamic Value')"
                        :options="tokens"
                        :required="false"
                        :disabled="!editable"
                        @change="insert_token"
                    />
                </div>
            </editor-menu-bar>

            <editor-content
                :editor="editor"
                class="nibnut-editor-content"
            >
                <div slot="content">{{ value }}</div>
            </editor-content>
        </div>

        <modal-dialog
            :id="`${id}-link-editor`"
            ref="link_editor"
            :show.sync="linking"
        >
            <div>
                <form-input
                    :id="`${id}-link-editor-url`"
                    name="link_url"
                    v-model="link_url"
                    :required="false"
                >
                    <template v-slot:label>{{ $root.translate("Url") }}</template>
                </form-input>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        class="mr-2"
                        @click.prevent="linking=false"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        class="ml-2"
                        @click.prevent="link_save"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script type="text/javascript">
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import {
    Editor,
    EditorMenuBar,
    EditorContent
} from "tiptap"
import {
    // Nodes
    Blockquote,
    HardBreak,
    Heading,

    // Marks
    Bold,
    Italic,
    Link,
    Strike,
    Underline,

    BulletList,
    ListItem
} from "tiptap-extensions"
import BaseEditorSmall from "./BaseEditorSmall"
import BaseEditorDynamicToken from "./BaseEditorDynamicToken"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "./FormInput"
import BaseDropdown from "./BaseDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "BaseEditor",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        EditorMenuBar,
        EditorContent,
        ModalDialog,
        FormInput,
        BaseDropdown,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        const extensions = [new HardBreak()]
        if(this.headers > 0) extensions.push(new Heading({ maxLevel: 3 }))
        if(this.styles) {
            extensions.push(new Bold())
            extensions.push(new Italic())
            extensions.push(new Underline())
            extensions.push(new Strike())
        }
        if(this.lists) {
            extensions.push(new BulletList())
            extensions.push(new ListItem())
        }
        if(this.quotes) extensions.push(new Blockquote())
        if(this.links) extensions.push(new Link())
        if(!!this.tokens && !!this.tokens.length) extensions.push(new BaseEditorDynamicToken())

        extensions.push(new BaseEditorSmall())

        this.editor = new Editor({
            extensions,
            editable: this.editable,
            onUpdate: ({ getHTML }) => {
                this.editor_html = getHTML()
            },
            onBlur: () => {
                const value = (this.editor_html === "<p></p>") ? null : this.editor_html
                if(this.editable) this.$emit("input", value, this.name)
            }
        })
        this.reseed()
    },
    beforeDestroy () {
        if(this.editor) {
            this.editor.destroy()
            this.editor = null
        }
    },
    watch: {
        dataVersion: "reseed",
        editable: "refresh_ui"
    },
    methods: {
        reseed () {
            if(this.editor) {
                this.editor_html = this.value
                this.editor.setContent(this.value)
            }
        },
        refresh_ui () {
            this.editor.setOptions({ editable: this.editable })
        },
        insert_token (event) {
            this.token_id = event.target.value || ""
            const token = this.tokens.find(token => token.id === this.token_id)
            this.editor.commands.dynamic_token(token)
            /*
            if(token) {
                const { state, view } = this.editor
                const { selection } = state

                const element = document.createElement("SPAN")
                element.contenteditable = "false"
                element.className = "dynamic-token"
                element.dataset.tokenId = token.id
                element.innerHTML = token.name
                console.log("DEBUG", element)

                const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
                const transaction = state.tr.insert(selection.anchor, slice.content)

                view.dispatch(transaction)
            }
            */

            setTimeout(() => {
                this.token_id = 0
            }, 50)
        },
        link_edit (command, attrs) {
            this.link_save_cmd = command
            this.link_url = attrs.href
            this.linking = true
            this.$nextTick(() => {
                this.$refs.link_editor.$el.getElementsByTagName("input")[0].focus()
            })
        },
        link_save () {
            if(!this.link_url) this.link_save_cmd({ href: null })
            else {
                if(!this.link_url.match(/^(?:(?:https|mailto|tel)?:)?\/\//i)) this.link_url = "https://" + this.link_url
                this.link_save_cmd({ href: this.link_url })
                this.linking = false
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        dataVersion: {
            required: true
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        headers: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 6),
            default: 3
        },
        styles: {
            type: Boolean,
            default: true
        },
        links: {
            type: Boolean,
            default: true
        },
        lists: {
            type: Boolean,
            default: true
        },
        quotes: {
            type: Boolean,
            default: true
        },
        tokens: {
            type: Array,
            default () {
                return []
            }
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            editor: null,
            editor_html: "",

            link_save_cmd: null,
            link_url: null,
            linking: false,

            query: null,
            suggestion_range: null,
            token_id: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-editor-content {
    span.dynamic-token {
        border: 1px dashed lighten($secondary-color, 12%);
        color: $secondary-color;
        padding: 0 0.2em;
    }
}
.nibnut-editor {
    & > div:first-of-type {
        background: $bg-color-light;
        background-image: none;
        border: $border-width solid $border-color-dark;
        border-radius: $border-radius;
        color: $body-font-color;
        display: block;
        font-size: $font-size;
        line-height: $line-height;
        max-width: 100%;
        position: relative;
        transition: background .2s, border .2s, box-shadow .2s, color .2s;
        width: 100%;

        .nibnut-editor-toolbar {
            padding: $control-padding-y $control-padding-x;
            background-color: $bg-color-light;

            .form-select {
                width: auto;
            }
        }
        .nibnut-editor-content {
            box-shadow: 0 0.1rem 0.1rem rgba(48,55,66,.1) inset;
            overflow: auto;

            & > .ProseMirror {
                height: 100%;
                appearance: none;
                outline: none;
                padding: ($control-padding-y * 2) $control-padding-x $control-padding-y $control-padding-x;

                &:focus {
                    border-color: transparent;
                }

                & > :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.nibnut-editor-sm {
        & > div:first-of-type > .nibnut-editor-content {
            height: 2rem;
        }
    }
    &.nibnut-editor-md {
        & > div:first-of-type > .nibnut-editor-content {
            height: 3.5rem;
        }
    }
    &.nibnut-editor-lg {
        & > div:first-of-type > .nibnut-editor-content {
            height: 7rem;
        }
    }
    &.nibnut-editor-full {
        display: flex;
        flex-direction: column;

        & > div {
            flex: 0 0 auto;
        }
        & > div:last-of-type {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;

            & > .nibnut-editor-toolbar {
                flex: 0 0 auto;
            }
            & > .nibnut-editor-content {
                flex: 1 0 auto;
            }
        }
    }
}
</style>
