<template>
    <form-group
        v-bind="form_group_props"
        :full-height="fullHeight"
    >
        <template v-slot:read_only>
            <div class="divider"></div>
            <div
                v-html="value"
                class="nibnut-editor-content"
            ></div>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <base-editor
            v-bind="editor_props"
            v-on="$listeners"
        />
    </form-group>
</template>

<script type="text/javascript">
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseEditor from "./BaseEditor"

export default {
    name: "FormEditor",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseEditor
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        editor_props () {
            const { required, saving, error, ...editor_props } = this.$props
            return editor_props
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        dataVersion: {
            required: true
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        headers: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 6),
            default: 3
        },
        styles: {
            type: Boolean,
            default: true
        },
        links: {
            type: Boolean,
            default: true
        },
        lists: {
            type: Boolean,
            default: true
        },
        quotes: {
            type: Boolean,
            default: true
        },
        tokens: {
            type: Array,
            default () {
                return []
            }
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        fullHeight: {
            type: Boolean,
            default: true
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
