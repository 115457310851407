<template>
    <div
        role="combobox"
        aria-haspopup="listbox"
        :aria-owns="`${id}-menu`"
        :aria-expanded="expanded"
        :class="{ 'autocomplete-oneline': !multiple, 'glyphless': !glyph }"
        class="form-autocomplete nibnut-select"
    >
        <div class="form-autocomplete-input form-input">
            <slot name="selection"></slot>

            <base-input
                ref="field"
                :id="id"
                type="text"
                :name="name"
                :value="field_value"
                :placeholder="placeholder"
                autocomplete="off"
                :aria-controls="`${id}-menu`"
                :disabled="disabled"
                :required="required"
                @focus="maybe_expand"
                @touchstart="touchstart"
                @keyup="keyup"
                @input="update_query"
                @keydown="keydown"
                @blur="blur"
            />
            <slot name="right_addon" :option="selected_option">
                <open-icon
                    v-if="!!glyph"
                    :glyph="glyph"
                />
            </slot>
        </div>

        <ul
            v-if="expanded"
            :id="`${id}-menu`"
            ref="menu"
            role="listbox"
            class="menu"
        >
            <li
                v-for="(option, index) in available_options"
                :key="option[idField]"
                role="option"
                tabindex="-1"
                class="menu-item"
            >
                <a
                    href
                    :class="{ disabled: !!option.disabled, active: ((active_choice_index < 0) && (value === option[idField])) || (active_choice_index === index) }"
                    tabindex="-1"
                    @mousedown="pick(option)"
                    @click.prevent
                >
                    <slot name="option" :option="option">
                        <span v-html="highlighted_label(option)"></span>
                    </slot>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
// Inspired by https://24ways.org/2019/making-a-better-custom-select-element/
// ** using mousedown on options instead of click, so it doesn't interfere with menu's blur event (https://stackoverflow.com/questions/50313603/vuejs-on-blur-for-custom-select-element-not-working)
// Autofill rules: https://www.codementor.io/@leonardofaria/disabling-autofill-in-chrome-zec47xcui

import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import BaseInput from "./BaseInput"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "BaseSelect",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        BaseInput,
        OpenIcon
    },
    watch: {
        options: "reset_expanded_state",
        disabled: "maybe_collapse"
    },
    methods: {
        reset_expanded_state () {
            if(document.activeElement === this.$refs.field.$el) this.set_expanded_state(this.queried || !!this.options.length)
        },
        maybe_collapse () {
            if(this.disabled) this.set_expanded_state(false, true)
        },
        set_expanded_state (expanded, force = false) {
            if(this.expanded !== expanded) {
                this.active_choice_index = -1
                if(!this.disabled || force) this.expanded = expanded
            }
        },
        maybe_expand (event) {
            event.target.select()
            if(this.expandOnFocus && !!this.options.length) this.expanded = true
            if(!this.focused) {
                this.query = event.target.value
                this.focused = true
            }
            this.$emit("focus")
        },
        emit_change: debounce(function (event) {
            this.$emit("search", this.query)
        }, 300),
        update_query (event) {
            this.active_choice_index = -1
            this.query = event.target.value
            this.emit_change()
        },
        keyup (event) {
            if(this.alphanumeric && this.iOS) this.touching = false

            let new_index
            switch (event.key) {
            case "ArrowDown":
                new_index = this.active_choice_index
                do {
                    new_index++
                    if(new_index >= this.available_options.length) new_index = 0
                } while(!!this.available_options[new_index].disabled && (new_index !== this.active_choice_index))
                this.active_choice_index = new_index
                break

            case "ArrowUp":
                new_index = this.active_choice_index
                do {
                    new_index--
                    if(new_index < 0) new_index = this.available_options.length - 1
                } while(!!this.available_options[new_index].disabled && (new_index !== this.active_choice_index))
                this.active_choice_index = new_index
                break

            case "Enter":
                if((this.active_choice_index >= 0) && !this.available_options[this.active_choice_index].disabled) {
                    this.pick(this.available_options[this.active_choice_index])
                    this.$refs.field.$el.blur()
                } else this.autopick()
                break

            case "Escape":
                this.set_expanded_state(false)
                this.picking = false
                this.focused = false
                this.$refs.field.$el.blur()
                break

            case "Shift":
            case "Tab":
            case "Control":
            case "Alt":
            case "Meta":
                break
            }
        },
        autopick () {
            let option = null
            if(this.active_choice_index >= 0) option = this.available_options[this.active_choice_index]
            else if(this.query) {
                option = this.available_options.find(option => {
                    return !option.disabled && (this.query.toLowerCase() === option[this.labelField].toLowerCase())
                })
            } else {
                option = this.available_options.find(option => {
                    return !option.disabled && (option[this.idField] === this.emptyValue)
                })
            }
            if(!option) {
                // option = { [this.idField]: this.emptyValue }
                if(!this.available_options.length || (typeof this.available_options[0][this.idField] === "undefined")) {
                    this.query = ""
                }
            }
            this.pick(option)
            this.set_expanded_state(false)
        },
        blur (event = null, picking = null) {
            if(this.alphanumeric && this.iOS) this.touching = false
            if(!this.focused) return

            if(event && !this.picking) {
                const is_menu_click = !!event.relatedTarget && !!this.$refs.menu && this.$refs.menu.contains(event.relatedTarget)
                if(!is_menu_click) this.autopick()
            } else this.set_expanded_state(false)
            if(picking !== null) this.picking = picking

            this.focused = false
        },
        pick (option) {
            if(!this.disabled && (!option || (typeof option[this.idField] !== "undefined"))) {
                this.picking = true
                this.$emit("input", option ? option[this.idField] : this.emptyValue, this.name, option || { [this.idField]: this.emptyValue, [this.labelField]: this.emptyLabel })
                if(this.multiple) this.query = ""
                this.$nextTick(() => {
                    this.blur()
                    this.picking = false
                })
            }
        },
        highlighted_label (option) {
            let label = ""
            if(option) label = option[this.labelField]
            if(label && this.query && !!option[this.idField]) {
                const length = this.query.length
                const index = label.toLowerCase().indexOf(this.query.toLowerCase())
                if(index >= 0) label = `${label.substring(0, index)}<strong class="text-primary">${label.substring(index, index + length)}</strong>${label.substring(index + length)}`
            }
            return label
        }
    },
    computed: {
        field_value () {
            // console.log("field_value", { focused: this.focused, query: this.query, selected_value: this.selected_value })
            if(this.focused) return this.query
            return this.selected_value
        },
        queried () {
            return !!this.query.length
        },
        selected_option () {
            return this.available_options.find(option => option[this.idField] === this.value) || {}
        },
        selected_value () {
            if(this.value === this.emptyValue) return this.required ? "" : this.emptyLabel
            const option = this.available_options.find(option => option[this.idField] === this.value)
            if(option) return option[this.labelField]
            if(this.value) return this.value
            return this.emptyLabel
        },
        placeholder () {
            if(this.required && (this.value === this.emptyValue)) return this.emptyLabel
            return ""
        },
        available_options () {
            const options = this.options.slice()
            if(!this.required && !!this.emptyLabel) options.unshift({ [this.idField]: this.emptyValue, [this.labelField]: this.emptyLabel })

            if(this.loading) options.push({ [this.labelField]: `(${this.$root.translate("Loading...")})`, disabled: true })
            else if(!options.length && this.queried) {
                options.push({ [this.labelField]: `(${this.$root.translate("No results found...")})`, disabled: true })
            }

            return options
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        glyph: {
            type: String,
            default: "caret-down"
        },
        expandOnFocus: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            query: "",
            expanded: false,
            active_choice_index: -1,
            picking: false,
            focused: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-autocomplete.nibnut-select {
    .form-autocomplete-input {
        padding-top: 3px;
        padding-bottom: 3px;

        & > input {
            padding-right: $unit-5;
        }

        & > i.las {
            position: absolute;
            right: $control-padding-x;
            bottom: $control-padding-y * 2;
            pointer-events: none;
        }
    }
    .menu {
        z-index: $zindex-4;

        .menu-item {
            a.disabled {
                &, &:hover, &:visited, &:focus {
                    background: transparent;
                    color: $gray-color;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }
}
</style>
