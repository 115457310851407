import { Node } from "tiptap"
import { replaceText } from "tiptap-commands"
import { Fragment } from "prosemirror-model"

export default class BaseEditorDynamicToken extends Node {
    get name () {
        return "dynamic_token"
    }

    get_label (dom) {
        return dom.innerText
    }

    create_fragment (schema, label) {
        return Fragment.fromJSON(schema, [{ type: "text", text: label }])
    }

    insert_token (range, attrs, schema) {
        const nodeType = schema.nodes[this.name]
        const nodeFragment = this.create_fragment(schema, attrs.label)
        return replaceText(range, nodeType, attrs, nodeFragment)
    }

    get schema () {
        return {
            attrs: {
                id: {},
                label: {}
            },
            inline: true,
            group: "inline",
            content: "text*",
            atom: true,
            draggable: true,
            selectable: true,
            parseDOM: [
                {
                    tag: "span[data-token-id]",
                    getAttrs: dom => {
                        const id = dom.getAttribute("data-token-id")
                        const label = this.get_label(dom)
                        return { id, label }
                    },
                    getContent: (dom, schema) => {
                        const label = this.get_label(dom)
                        return this.create_fragment(schema, label)
                    }
                }
            ],
            toDOM: node => [
                "span",
                {
                    class: "dynamic-token",
                    "data-token-id": node.attrs.id
                },
                node.attrs.label
            ]
        }
    }

    commands ({ schema }) {
        return attrs => this.insert_token(null, attrs, schema)
    }
}
