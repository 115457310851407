import { Mark } from "tiptap"
import { toggleMark } from "tiptap-commands"

export default class BaseEditorSmall extends Mark {
    get name () {
        return "small"
    }

    get schema () {
        return {
            parseDOM: [
                {
                    tag: "small"
                }
            ],
            toDOM: () => ["small", 0]
        }
    }

    keys ({ type }) {
        return {
            "Mod--": toggleMark(type)
        }
    }

    commands ({ type }) {
        return () => toggleMark(type)
    }
}
